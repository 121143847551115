import React from 'react';
import styled, {withTheme, css} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';
import {Link} from "gatsby";

// Styles
import {Column, Flex, Head, Outline, Section, SmallHead, Text} from "../styles";

// Components
import { Card, Space, Button, Carousel, Background, Dots, Line, ListIcon, withCarouselState, Visible } from "../components";

// Image
import arrow from '../images/arrow.svg';

// Top Icons
import Gaming from '../images/icons/Gaming.svg'
import Gambling from '../images/icons/Gambling.svg'
import Knives from '../images/icons/Knives.svg'
import Solvents from '../images/icons/Solvents.svg'
import Fireworks from '../images/icons/Fireworks.svg'
import cigarettes from '../images/icons/E-cigarrets.svg'

// Down Icons
import Lottery from '../images/icons/Lottery.svg'
import Sunbeds from '../images/icons/Sunbeds.svg'
import Adult from '../images/icons/Adult Entertainment.svg'
import Pharmaceuticals from '../images/icons/Pharmaceuticals.svg'
import Firearms from '../images/icons/Firearms.svg'

const TOP_ICONS = [
    [ 'Gaming', Gaming ],
    [ 'Gambling', Gambling ],
    [ 'Knives', Knives ],
    [ 'Solvents', Solvents ],
    [ 'Fireworks', Fireworks ],
    [ 'E-cigarettes', cigarettes ],
];

const BOTTOM_ICONS = [
    [ 'Lottery', Lottery],
    [ 'Sunbeds', Sunbeds],
    [ 'Adult Entertainment', Adult],
    [ 'Pharmaceuticals', Pharmaceuticals],
    [ 'Firearms', Firearms],
];

const CONTENT = [
    ['/e-cigarettes', 'E-cigarettes and vapeing', 'In the US and the UK, the use and sale of e-cigarettes to persons over the age of 18 is legal. A photo ID is required in order to purchase them. AgePass enables online retailers to verify online purchasers are over the age of 18 at time of sale.'],
    ['/adult-content', 'Adult Content', 'Under the DEA, people accessing adult content from the UK will now need to verify that they are over 18 using ID. AgePass enables users to seamlessly verify their age quickly and securely.'],
    ['/gaming-gambling', 'Gaming and Gambling', 'Online gambling sites are required by law to demonstrate that they have taken reasonable steps to determine that their players are over the age of 18. AgePass enables online gambling sites to verify the age of their online users.'],
    ['/solvents', 'Solvents', 'It is illegal for any person to sell any substance to any person under the age of 18. A photo ID is required in order to purchase them AgePass enables online retailers to verify online purchasers are over the age of 18 at time of sale.'],
    ['/lottery', 'Lottery', 'The national Lottery is governed in the UK by Camelot. Under this legislation, it is illegal for a National Lottery ticket to be sold by, or to, a person under the age of 16. AgePass enables online retailers to verify online purchasers are over the age of 16 at time of sale.'],
    ['/dvd-video', 'DVD and Video', 'Videos, DVDs and Blu Ray discs are classified by the BBFC depending on their content. It is illegal to supply a classifies video work to a person who has not attained the age of classification. AgePass enables online retailers to verify online purchasers are over the required age at time of sale.'],
    ['/tobacco', 'Tobacco', 'It is illegal to sell cigarettes or other forms of tobacco, or tobacco products including cigarette papers, to a person under 18 years of age. AgePass enables online retailers to verify online purchasers are over the age of 18 at time of sale.'],
    ['/sunbeds', 'Sunbeds', 'Sunbeds are one of the newest products to be age restricted, and it is now an offence for a sunbed business to allow or invite a person under the age of 18 to use a sunbed. For shops that take online bookings, AgePass can verify that the person making the booking is age 18 or over.'],
    ['/knives', 'Knives', 'The sale of knives and bladed instruments is heavily restricted, and it is illegal to sell knives to a person under the age of 18. AgePass enables online retailers to verify purchasers are over the age of 18 at the time of sale.']
]

const SectionOnlineAgeVerification = ({ theme, carousel }) => {
    const { state, actions } = carousel;
    const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);
    const isDesktop = useMedia(`(min-width: ${theme.sizes.desktop + 1 }px)`);

    return (
        <Section id="onlineAgeVerification">
            <Background 
                color={theme.secondaryColor} 
                padding={{phone: '0', def: '47px 60px 0 0', laptop: '43px 118px 50px 124px'}}
            >
                <TopIconContainer>
                    <ListIcon icons={TOP_ICONS} />
                </TopIconContainer>
                <Card
                    margin={{phone: '0 20px',  def: '0 0 0 -22px', laptop: '0'}}
                    padding={cardStyle.padding}
                    spacing={cardStyle.spacing}
                >
                    <Carousel
                        current={state.current}
                        onChildrenCount={actions.setNumber}
                        interval={5000}
                        autoplay
                    >
                        {CONTENT.map(( ([url, title, text]) => (<SmallHead key={title}>{title}</SmallHead>)))}
                    </Carousel>
                    <Head>
                        Online Age Verification
                    </Head>
                    <Carousel
                        onChange={actions.setCurrent}
                        current={state.current}
                        onChildrenCount={actions.setNumber}
                        interval={5000}
                        autoplay
                    >
                        {CONTENT.map(( ([url, title, text]) => (<Text margin="0" key={title}>{text}</Text>)))}
                    </Carousel>

                    <Space height={isTablet ? 27 : 25}/>
                    <Button link={CONTENT[state.current][0]} height={isTablet ? 35 : 47} width={isTablet ? '100%' : '200px'}>
                            Read More    
                    </Button>
                    <DotsContainer>
                        <Dots number={state.number} onClick={actions.setCurrent} active={state.current}/>
                    </DotsContainer>
                </Card>
                <Flex css={flexStyle} alignItems="flex-end">
                    <DownIconContainer>
                        <ListIcon padding={listIconPadding} icons={BOTTOM_ICONS} />
                    </DownIconContainer>
                    <Visible value={!isTablet}>
                        <Column padding="0 0 18px 0">
                            <Line height="155px" width="2px"/>
                        </Column>
                        <NextContainer>
                            <OutlineContainer css={styleOutline}>
                                <Outline>How does it work?</Outline>
                            </OutlineContainer>
                            <Link to="#howItWorks">
                                <Next src={arrow} alt="Next"/>
                            </Link>
                        </NextContainer>

                    </Visible>
                </Flex>
                <Visible value={isTablet}>
                    <Line height="2px" width="240px" css={lineStyle}/>
                    <NextWrapper>
                        <OutlineContainer>
                            <Outline>How does it work?</Outline>
                        </OutlineContainer>
                        <Link to="#howItWorks">
                            <Next src={arrow} alt="Next"/>
                        </Link>
                    </NextWrapper>
                </Visible>
            </Background>
        </Section>
    )
};


export default withTheme(withCarouselState(SectionOnlineAgeVerification));

const NextWrapper = styled.div`
    position: relative;
`;

const OutlineContainer = styled.div`
    width: 146px;
    height: 58px;
    margin: 0 0 28px 30px;

    ${media.laptop`
        margin: 25px 10px 30px 80px;    
    `}

    ${media.phone`
        margin: 25px 10px 30px 60px;    
    `}
`;

const styleOutline = css`
    position: absolute;
    right: 0;
    bottom: 42px;
`;

const TopIconContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0 0 0;
    margin: 0 0 35px;
    width: 88%;

    ${media.desktop`
        max-width: 1142px;
        padding: 20px 0 0 35px;
    `}

    ${media.laptop`
        margin: 0;
        width: auto;
    `}

    ${media.phone`
        margin: 0 0 20px 29px;
        width: 87%;
    `} 
`;

const DownIconContainer = styled.div`
    margin: 45px 0;
    width: 72%;

    ${media.laptop`
        width: 100%;
        margin: 0;
    `}

    ${media.phone`
        width: 95%;
        margin: 30px 0 12px 12px;
    `}
`;


const listIconPadding = css`
    padding: 0 10px 0 19px;

    ${media.desktop`
        padding: 0 10px 0px 35px;
    `}

    ${media.laptop`
        padding: 0;        
    `}
`;


const flexStyle = css`
    position: relative;
    ${media.desktop`
        max-width: 1258px;
    `}
`;


const DotsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ${media.desktop`
        margin-top: 54px;
    `}

    ${media.laptop`
        justify-content: center;
        margin: 10px 0 0 0;
    `}

    ${media.phone`
        justify-content: center;
        margin: 10px 0 0 0;
    `}
`;

const Next = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 11;
    transform: translateY(15px);

    ${media.laptop`
        width: 55.8px;
        height: 55.8px;
        bottom: -90px;
        right: 16px;
    `}

    ${media.phone`
        width: 55.8px;
        height: 55.8px;
        bottom: -40px;
        right: 42px;
    `}
`;

const NextContainer = styled.div`
	position: absolute;
	display: flex;
	right: 16px;
`;

const cardStyle = {
    padding: css`
        padding: 20px 20px 20px 20px;
    
        ${media.desktop`
            padding: 76px 20px 20px 95px;
        `}

        ${media.laptop`
            padding: 0;
        `}

        ${media.phone`
            padding: 0;
        `}
    `,
    spacing: css`
        padding: 30px 21px 16px 22px;

        ${media.laptop`
            padding: 58px 86px 64px 80px;
        `}

        ${media.phone`
            padding: 30px 36px 36px 43px;
        `}
    `
}

const lineStyle = css`
    display: block;
    margin: 15px auto 40px;
`;
