import React from 'react';
import styled, {withTheme, css} from 'styled-components';
import { media } from '../theme';

// Styles
import {Column, Flex, Head, Section, Text} from "../styles";

// Components
import { Background, Button, Visible } from "../components";

// Images
import paysave from '../images/image-paysafecard.png'
import yoti from '../images/image-yoti.png'

import useMedia from 'react-use/lib/useMedia';

const SectionPaysafe = ({ theme }) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);

    return (
        <Section>
            <Background color={theme.secondaryColor}>
                <Wrapper>
                    <Flex direction={isTablet ? 'column' : 'row'}>
                        <Column width={isTablet ? 200 : 388}>
                            <Head>
                                We also work with paysafe
                            </Head>
                        </Column>
                        <Column css={columnStyle}>
                            <Text margin={isTablet ? '0 0 0 0' : null}>
                                paysafecard is a market leader in online prepaid payment methods. AgePass by AVSecure have a global agreement to use the 18+ paysafecard as proof of age.
                            </Text>
                            <Text margin={isTablet ? '20px 0 32px 0' : '23px 0' }>
                                Buy paysafecard at any of the 27,000 sales outlets in the UK and use it to verify your age at AgePass without revealing anything about your identity. A truly anonymous method and unique to AgePass.
                            </Text>
                            <Button
                                width={isTablet ? '100%' : '183.6px'}
                                link="https://www.paysafecard.com/en-gb/buy/find-sales-outlets/"
                                target="_blank"
                            >
                                Where to buy
                            </Button>

                        </Column>
                        <Visible value={!isTablet}>
                            <Column css={imageStyle}>
                                <PaysaveContainer>
                                    <Paysave src={paysave} alt="paysave"/>
                                </PaysaveContainer>
                            </Column>
                        </Visible>
                    </Flex>
                </Wrapper>
                <Wrapper>
                    <Flex direction={isTablet ? 'column' : 'row'}>
                        <Column width={isTablet ? 200 : 388}>
                            <Head>
                                ...and YOTI
                            </Head>
                        </Column>
                        <Column css={columnStyle}>
                            <Text margin={isTablet ? '0 0 0 0' : null}>
                                Yoti is a global digital identity platform and free consumer app that gives you a simple, fast and secure way of proving your identity across thousands of websites and retail stores.
                            </Text>
                            <Text margin={isTablet ? '20px 0 32px 0' : '23px 0' }>
                                The Yoti app combines your government-issued ID document with your unique facial features to create a secure digital identity that only you control using your phone.
                            </Text>
                            <Button
                                width={isTablet ? '100%' : '183.6px'}
                                link="https://www.yoti.com/?rfl=avsecure"
                                target="_blank"
                            >
                                Learn more
                            </Button>

                        </Column>
                        <Visible value={!isTablet}>
                            <Column css={imageStyle}>
                                <YotiContainer>
                                    <Yoti src={yoti} alt="yoti"/>
                                    {/* <Shadow/> */}
                                </YotiContainer>
                            </Column>
                        </Visible>
                    </Flex>
                </Wrapper>
            </Background>
        </Section>
    )
};

export default withTheme(SectionPaysafe);

const Wrapper = styled.div`
    padding: 50px 60px 10px 27px;
    height: 100%;

    ${media.desktop`
        padding: 50px 60px 10px 84px;

    `}

    ${media.laptop`
        padding: 51px 204px 10px 204px;
    `}

    ${media.phone`
        padding: 36px 60px 10px 60px;
    `}
`;

const PaysaveContainer = styled.div`
    transform: translateY(39px);
    width: 270px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Paysave = styled.img`
    height: 138px;
`;

const YotiContainer = styled(PaysaveContainer)`
    transform: translateY(60px);
    position: relative;
    padding-left: 25px;
`;

const Yoti = styled.img`
    z-index: 1;
    height: 138px;
`;

const Shadow = styled.div`
    position: absolute;
    border-radius: 1em;
    width: 75%;
    height: 35%;
    box-shadow: 0 -9px 45px rgba(45,45,45,0.6);
    z-index: 0;
`;

const imageStyle = css`
    ${media.desktop`
        margin-left: 8%;
    `}
`;

const columnStyle = css`
    width: 340px;

    ${media.desktop`
        width: 360px;
    `}

    ${media.laptop`
        width: auto;
    `}

    ${media.phone`
        width: auto;
    `}
`;