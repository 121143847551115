import React from 'react';
import styled, {withTheme, css} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Styles
import {Column, Flex, Section, Text} from "../styles/index";

// Components
import {Background, Space, Highlight, Button, Visible} from "../components/index";
import withSecondaryTheme from "../components/withSecondaryTheme";

// Images
import image from '../images/AVC.jpg';
import mobile from '../images/AVC-mock-up-mobile.png';
import logo from '../images/logo-menu.svg';
import stamp from '../images/stamp.png';
import epay from '../images/logos/epay.svg';
import paypoint from '../images/logos/paypoint.svg';

const SectionAVC = ({theme}) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);
    return (
        <React.Fragment>
            <Section height={isTablet ? '216px' : '307px'}>
                <Background src={isTablet ? mobile : image}/>
            </Section>
            <Section id="TAVC">
                <Background 
                    color="#383838"
                    padding={{phone: '0', def: '0', laptop: '0 145px 68px 145px'}} 
                >
                    <StampContainer>
                        <Stamp src={stamp} alt="stamp"/>
                    </StampContainer>
                    <Container>
                        <Flex>
                            <Logo src={logo} alt="logo"/>
                        </Flex>
                        <Space height="5"/>
                        <Flex direction={isTablet ? 'column' : 'row'}>
                            <Column css={columnStyle}>
                                <Text margin={isTablet ? '23px 0 16px 0' : ' 23px 0'}>
                                    You can buy the Age Verification Card e-Voucher over-the-counter at up to 60,000
                                    ePay and PayPoint stores across the UK, including major supermarket chains.
                                </Text>
                                <Visible value={!isTablet}>
                                    <Text margin={'70px 0 0'}>
                                        Available at
                                    </Text>
                                    <Flex justifyContent={'space-between'} css={`height: 80px; width: 85%;`}>
                                        <Paypoint src={paypoint} alt="paypoint"/>
                                        <Epay src={epay} alt="Epay"/>
                                    </Flex>
                                </Visible>
                            </Column>
                            <Space width="45"/>
                            <Column css={columnStyle}>
                                <Text margin={isTablet ? '0 0 32px 0' : ' 23px 0'}>It allows face-to-face age verification to be completed at the point of sale. If
                                    you appear to be over 18, no additional proof is required.
                                    If the retailer is unsure you are over 18 they may request to see an official photo
                                    ID, such as driving licence or passport, but <Highlight>no record of the documents
                                        will be retained.</Highlight>
                                </Text>
                                <Visible value={!isTablet}>
                                    <Button
                                        height="41"
                                        width={isTablet ? '100%' : '183px'}
                                        link="https://www.ageverificationcard.com/"
                                        target="_blank"
                                    >
                                        Find out more
                                    </Button>
                                </Visible>
                            </Column>
                        </Flex>
                        <Visible value={isTablet}>
                            <Button
                                height="35"
                                width="100%"
                                link="https://www.ageverificationcard.com/"
                                target="_blank"
                            >
                                Find out more
                            </Button>
                            <Flex 
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                css={`margin-top: 40px;`}
                            >
                                <Text>
                                    Available at
                                </Text>
                                <Wrapper>
                                    <Paypoint src={paypoint} alt="paypoint"/>
                                    <Epay src={epay} alt="Epay"/>
                                </Wrapper>
                            </Flex>
                        </Visible>
                        <Column padding={isTablet ? '30px 0' : '15px 0 0 0'}>

                        </Column>
                    </Container>
                </Background>
            </Section>
        </React.Fragment>
    )
};

export default withSecondaryTheme(withTheme(SectionAVC));

const Logo = styled.img`
    width: 253.7px;
    height: 52.1px;
    object-fit: contain;
    
    ${media.laptop`
        width: 187.5px;
        height: 38.5px;
    `}

    ${media.phone`
        width: 187.5px;
        height: 38.5px;
    `}
`;

const Container = styled.div`
    padding: 57px 58.7px 68px 30px;
    ${media.desktop`
        padding: 75px 58.7px 98px 84px;
    `}
    ${media.laptop`
        padding: 50px 61px 18px 61px;
    `}
    ${media.phone`
        padding: 50px 61px 18px 61px;
    `}
`;

const StampContainer = styled.div`
    position: relative;
`;
const Stamp = styled.img`
    position: absolute;
    top: 0;
    right: 115px;
    transform: translateY(-22px);
    
    ${media.laptop`
        right: 30px;
        height: 80px;
    `}

    ${media.phone`
        right: 30px;
        height: 80px;
    `}
`;

const Wrapper = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
`;

const Paypoint = styled.img`
    max-width: 100%;
    flex-shrink: 0;
    align-self: flex-end;

    ${media.desktop`
        margin: 0 75px 0 0;
    `}
    ${media.laptop`
        margin-right: 15%;
    `}
`;

const Epay = styled.img`
    max-width: 100%;
    flex-shrink: 0;
    align-self: flex-end;

    ${media.desktop`
        margin: 0 75px 0 0;
    `}
`;

const columnStyle = css`
    width: 254px;

    ${media.desktop`
        width: 360px;
    `}

    ${media.laptop`
        width: auto;
    `}

    ${media.phone`
        width: auto;
    `}
`;

const styleSpace = css`
    width: 45px;
    
    ${media.desktop`
        width: 30px;
    `}
`;