import React, {useState} from 'react';
import styled, {withTheme, css} from 'styled-components';
import {Link} from "gatsby";
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';


import {
    Background,
    Card,
    Button,
    Highlight,
    Space,
    Line,
    Visible,
    Lightbox
} from "../components";

// Styles
import {Column, Head, Outline, Section, SubHead, Text, Understroke} from "../styles";

// Images
import headImage from '../images/Image-header.jpg';
import play from '../images/play.svg';
import arrow from '../images/arrow.svg';
import video from '../asset/AVSecure.mp4';
import poster from '../asset/poster.jpg';

const SectionWhatIsAgePass = ({theme}) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);

    const [showVideo, setVisible] = useState(false);

    return (
        <Section height={isTablet ? '800px' : ''} id="agePass">
            <Background
                padding={{phone: '156px 19px 90px 19px', def: '110px 60px 90px 19px', laptop: '202px 118px 144px 124px'}}
                src={headImage}
            >
                <Flex alignItems="center">
                    <Card 
                        padding={padding}
                        
                        margin={{laptop: '0',  def: '20px 0 40px -40px'}}
                        basis
                    >
                        {/* TODO: Saved for 2n release*/}
                        <PlayAction>
                            <Image src={play} alt="Play" pointer onClick={() => {
                                setVisible(true)
                            }}/>
                            <Lightbox
                                show={showVideo}
                                onClose={() => setVisible(false)}
                                src={video}
                                poster={poster}
                            />

                        </PlayAction>
                        <NextAction>
                            <Link to="#onlineAgeVerification">
                                <Image src={arrow} alt="Next"/>
                            </Link>
                        </NextAction>
                        <Flex direction="row">
                            <Column css={styleColumn}>
                                <Head>
                                    What is AgePass?
                                </Head>
                                <SubHead>
                                    AgePass is a simple and secure way to verify your age online.
                                </SubHead>
                                <Column>
                                    <Text>
                                        AgePass offers multiple methods to verify your age. You can use driving licence,
                                        mobile phone, credit or debit card information, and passport.
                                    </Text>
                                </Column>
                                <Column>
                                    <Text style={{margin: '23px 0 0 0'}}>
                                        For industries that permit anonymous age verification, the highest levels of
                                        privacy are obtained by purchasing an <Highlight>Age Verification
                                        Card.</Highlight>
                                    </Text>
                                </Column>
                                <Space height="23"/>
                                <div>
                                    <Flex direction={isTablet ? 'column' : 'row'} alignItems="center">
                                        <Button
                                            height={isTablet ? 35 : 47}
                                            width={isTablet ? '100%' : '232.5px'}
                                            link={'https://agewallet.agepass.net/?external-signup=true'}
                                            target="_self"
                                        >
                                            Get an AgePass
                                        </Button>
                                        <Info>
                                            Already have an AgePass?
                                            <Understroke>
                                                <a 
                                                    href='https://agewallet.agepass.net/settings'
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Configure it by clicking here
                                                </a>
                                            </Understroke>
                                        </Info>
                                    </Flex>
                                </div>
                                <Visible value={isTablet}>
                                    <Line css={lineStyle} width="200px" height="2px"/>
                                    <Space height="10"/>

                                    <OutlineContainer>
                                        <Outline>Why do I need one?</Outline>
                                    </OutlineContainer>
                                </Visible>
                            </Column>
                            <Visible value={!isTablet}>
                                <Column padding="0 0 10px 200px">
                                    <Flex css={styleFlex} alignItems="flex-end">
                                        <Line css={styleLine} width="1.4px"/>
                                        <Space width="20"/>
                                        <OutlineContainer>
                                            <Outline>Why do I need one?</Outline>
                                        </OutlineContainer>
                                    </Flex>
                                </Column>
                            </Visible>

                        </Flex>

                    </Card>
                </Flex>

            </Background>
        </Section>
    )
};

export default withTheme(SectionWhatIsAgePass);

const lineStyle = css`
    display: block;
    margin: 15px auto;
`;

const Info = styled.span`
    margin: 10px;
    width: 151px;
    height: 52px;
    font-family: ${({theme}) => theme.fontFamily};
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
    color: ${({theme}) => theme.primaryColor};
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Flex = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: ${({direction = 'row'}) => direction };
    align-items: ${({alignItems = 'inherit'}) => alignItems};
`;

const styleFlex = css`
    ${media.desktop`
        height: 89.5%;
    `}
`;


const Image = styled.img`
  display: inline-block;
  cursor: ${props => props.pointer ? 'pointer' : 'auto'};
  
  ${media.laptop`
    width: 55.8px;
    height: 55.8px;
  `}

  ${media.phone`
    width: 55.8px;
    height: 55.8px;
  `}
`;

const PlayAction = styled.div`
    position: absolute;
    right: 16px;
    top: 14px;

    ${media.laptop`
        top: -20px;
    `}
`;

const NextAction = styled.div`
    position: absolute;
    right: 16px;
    bottom: -51px;

    ${media.desktop`
        bottom: -17px;
    `}

    ${media.laptop`
        bottom: -25px;
        right: 21px;
        ${Image}{
            width: 55.8px;
            height: 55.8px;
        }
    `}

    ${media.phone`
        bottom: -18px;
        right: 21px;
        ${Image}{
            width: 55.8px;
            height: 55.8px;
        }
    `}
`;

const OutlineContainer = styled.div`
    width: 162px;
    display: flex;
    align-items: flex-end;
`;

const styleColumn = css`
    padding: 50px 0 0 50px;

    ${media.desktop`
        padding: 116px 0 129px 117px;
    `}

    ${media.laptop`
        padding: 46px 86px 35px 80px;
    `}

    ${media.phone`
        padding: 40px 35px 35px 43px;
    `}
`;

const styleLine = css`
    height: 462px;

    ${media.desktop`
        height: 94%;
        transform: translateX(-70px);
    `}
`;

const padding = css`
    padding: 20px 0 26px 0;
    
    ${media.desktop`
        padding: 0;
    `}
`;
