import React, {useCallback} from 'react';
import styled, {withTheme, css} from 'styled-components';
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Styles
import {Column, Flex, Head, Section, Text} from "../styles/index";

// Components
import {Background, Card, Carousel, Dots, Space, Visible} from "../components/index";
import withCarouselState from "../components/withCarouselState";

// Images
import image from '../images/Image2.jpg';
import iphone from '../images/iphone.png';
import screen1 from '../images/carousel/1.Carousel-mobile.png';
import screen2 from '../images/carousel/2.Carousel-mobile.png';
import screen3 from '../images/carousel/3.Carousel-mobile.png';
import screen4 from '../images/carousel/4.Carousel-mobile.png';

const labels = [
    '1. Choose a Verification Method ',
    '2. Securely submit information ',
    '3. Receive your Access Key and Set your PIN',
    '4. You’re successfully verified!',
];

const screens = [
    screen1,
    screen2,
    screen3,
    screen4,
];

const SectionHowItWorks = ({carousel, theme}) => {
    const {state, actions} = carousel;
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);
    const isMobile = useCallback((a, b) => isTablet ? a : b, [isTablet]);

    return (
        <Section id="howItWorks">
            <Background padding={{phone: '60px 19px 0 19px', def: '74px 60px 68px 0', laptop: '74px 118px 68px 124px'}} src={image}>
                <Flex alignItems="center">
                    <Card
                        spacing={spacing}
                        margin={{phone: '0',  def: '0 0 0 -22px'}}
                        maxWidth={'898px'}
                        wrapWidth={'80%'}
                    >
                        <Head>How it works</Head>
                        <Column css={styleColumn}>
                            <Text>
                                Once you have verified your age by any of the methods available, AgePass will generate a unique “Access Key” which is combined with a simple 4-digit PIN created by you.
                            </Text>
                        </Column>
                        <Column css={styleColumn}>
                            <Text>
                                For industries where identity provision is not required, the highest levels of privacy are obtained by purchasing an Age Verification Card, and entering the unique 16-digit number from the card.
                            </Text>
                        </Column>

                        <IphoneContainer>
                            <Iphone src={iphone} alt="phone">
                                <Window>
                                    <Carousel
                                        onChildrenCount={actions.setNumber}
                                        onChange={actions.setCurrent}
                                        current={state.current}
                                        interval={5000}
                                        autoplay
                                    >
                                        {screens.map(screen => <Screen key={screen} src={screen} alt="screen"/>)}
                                    </Carousel>
                                </Window>
                            </Iphone>
                        </IphoneContainer>
                        <Column css={dotStyle}>
                            <Flex 
                                alignItems={isMobile('center', 'unset')}
                                justifyContent={isMobile('center', 'unset')}
                            >
                                <Dots number={state.number} active={state.current} onClick={actions.setCurrent}/>
                                <Space height={isMobile(50, 40)}/>
                            </Flex>
                        </Column>
                        <List>
                            {labels.map((label, index) => (
                                <Item
                                    key={label}
                                    active={index === state.current}
                                    onClick={() => actions.setCurrent(index)}>
                                    {label}
                                </Item>
                            ))}
                        </List>
                        <Visible value={isTablet}>
                            <BG />
                        </Visible>
                    </Card>
                </Flex>
            </Background>
            
        </Section>
    )
};

export default withCarouselState(withTheme(SectionHowItWorks));

const Iphone = styled.div`
    background: center / contain url(${props => props.src}) no-repeat;
    height: 600px;
    width: 300px;
    position: relative;
    background-size: 100%;

    ${media.laptop`
        background-size: 300px;
    `}

    ${media.phone`
        background-size: 300px;
    `}
`;

const dotStyle = css`
    margin: 45px 0 -10px;
`;

const IphoneContainer = styled.div`
    position: absolute;
    right: 0;
    top: 62px;
    overflow: hidden;
    transform: translateX(263px);

    ${media.desktop`
        top: 20px;
        transform: translateX(210px);
        zoom: 0.9;
    `}

    ${media.laptop`
        position: relative;
        transform: none;
        display: flex;
        justify-content: center;
        zoom: 0.9;
        overflow: hidden;
        height: 400px;
        margin: -75px 0 8px 0; 
    `}

    ${media.phone`
        position: relative;
        transform: none;
        display: flex;
        justify-content: center;
        zoom: 0.75;
        overflow: hidden;
        height: 400px;
        margin: -25px 0 8px 0; 
    `}
`;

const BG = styled.div`
    position: absolute;
    background-color: ${({theme}) => theme.secondaryColor};
    width: 100%;
    height: 28%;
    z-index: -1;

    ${media.laptop`
        height: 260px;
        transform: translate(-100px, -220px);
    `}

    ${media.phone`
        height: 28%;
        transform: translate(-42px, -227px);

    `}
`;

const Screen = styled.img`
    height: 100%;
    width: 100%;
`;

const Window = styled.div`
    top: 75px;
    left: 28px;
    position: absolute;
    background: #fff;
    height: 432px;
    width: 248px;

    ${media.laptop`
        top: 77px;
        left: 28px;
    `}

    ${media.phone`
        top: 77px;
        left: 28px;
    `}
`;

const List = styled.ul`
    padding: 0;
    margin: 9px 0 0 0;
    max-width: 370px;
    ${media.desktop`
        max-width: 510px;
        height: 170px;
    `}  

    ${media.laptop`
        max-width: 350px;
        height: 180px;
    `}  

    ${media.phone`
        max-width: 300px;
    `}    
`;

const Item = styled.li`
    opacity: ${({active}) => active ? 1 : 0.4 };
    font-family: ${({theme}) => theme.fontFamily};
    font-size: ${({active}) => active ? '26px' : '20px' };;
    font-weight: bold;
    line-height: 2;
    text-align: left;
    color: ${({theme}) => theme.primaryColor};
    transition: opacity, font-size 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    list-style: none;

    ${media.laptop`
        font-size: ${({active}) => active ? '18px' : '16px' };;
    `}

    ${media.phone`
        font-size: ${({active}) => active ? '18px' : '16px' };;
    `}
`;

const styleColumn = css`
    width: 350px;

    ${media.desktop`
        max-width: 462px;
        width: auto;
    `}

    ${media.laptop`
        width: auto;
    `}

    ${media.phone`
        width: auto;
    `}
`;

const spacing = css`
    padding: 58px 155px 64px 50px;
    
    ${media.desktop`
        padding: 58px 155px 64px 115px
    `}

    ${media.laptop`
        padding: 51px 85px 24px 100px;
    `}

    ${media.phone`
        padding: 38px 33px 24px 42px;
    `}
`;
