import React from 'react';
import { Page } from '../components';
import MainContentContainer from '../sections/MainContentContainer';


const IndexPage = () => (
	<Page>
		<MainContentContainer />
	</Page>
);

export default IndexPage;
