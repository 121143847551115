import React from 'react';
import styled, {withTheme} from 'styled-components';
import {Link as GatsbyLink} from "gatsby";

// Components
import {Line, Space, Logo} from "../components";
import withSecondaryTheme from "../components/withSecondaryTheme";

// Styles
import {Flex} from "../styles/index";

const items = [
    ['Online Age Verification', '#onlineAgeVerification'],
    ['Privacy Policy', '/privacy-policy'],
    ['How it works', '#howItWorks'],
    ['Terms & Conditions', '/terms-conditions'],
    ['The Age Verification Card', '#TAVC'],
    ['FAQs', '/faqs'],
    ['Visit avsecure.com', 'https://www.avsecure.com', '_blank'],
    ['Contact', '#contact'],
];

const FooterMobileNavigation = () => {
    return (
        <Wrapper>
            <Flex>
                <Logo width="102.6px" height="34.6px"/>
            </Flex>
            <List>
                {items.map(([title, href, target], index) => {
                    return (
                        <Item key={title} width={(index + 1) % 2 === 0 ? '40%' : '60%'}>
                            {target ? <ExternalLink href={href} target={target}>{title}</ExternalLink> : <Link to={href}>{title}</Link>}
                        </Item>
                    )
                })}
            </List>
            <Space/>
            <Line height="2px" width="370px"/>
        </Wrapper>
    )
};

export default withTheme(withSecondaryTheme(FooterMobileNavigation));

const Link = styled(GatsbyLink)`
    text-decoration: none;
    font-family: ${({theme}) => theme.fontFamily};
    height: 17px;
    opacity: 0.3;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: ${({theme}) => theme.primaryColor};
`;

const ExternalLink = styled.a`
    text-decoration: none;
    font-family: ${({theme}) => theme.fontFamily};
    height: 17px;
    opacity: 0.3;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: ${({theme}) => theme.primaryColor};
`;

const Item = styled.li`
    width: ${({width = '50%'}) => width};
    text-align: left;
    margin: 8px 0;
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 16px 0 12px 0;
`;

const Wrapper = styled.div`
    padding: 45px 20px 0px 20px;
`;