import React, {useCallback} from 'react';
import useMedia from 'react-use/lib/useMedia';
import {withTheme, css} from "styled-components";
import { media } from '../theme';


// Styles
import {Column, Flex, Section, SubHead, Text} from "../styles";

// Components
import {Background, Card, Line, Space} from "../components";

// Images
import image from '../images/Image-header.jpg';


const SectionWhatMakesAgePass = ({theme}) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);
    const isMobile = useCallback((a, b) => isTablet ? a : b, [isTablet]);

    return (
        <Section>
            <Background
                position={'top'}
                padding={{phone: '54px 19px', def: '0', laptop: '0 118px 68px 124px'}} 
                src={isMobile(image, null)}
            >
                <Card
                    spacing={spacing}
                    margin={{phone: '0',  def: '0 0 0 -22px'}}
                    maxWidth={'100%'}
                >
                    <Column width={isMobile('260', '390')}>
                        <SubHead>
                            What makes AgePass by AVSecure the best choice for consumers?
                        </SubHead>
                    </Column>
                    <Space height="20"/>
                    <Flex justifyContent={isTablet ? 'center' : 'baseline'}>
                        <Line width={isTablet ? '200px' : '600px'} height="2px"/>
                    </Flex>
                    <Space height="10"/>
                    <Flex direction={isTablet ? 'column' : 'row'}>
                        <Column css={columnStyle} padding="0 20px 0 0">
                            <Text css={textStyle}>
                                We really care about privacy! In a world where data breaches and hacks are too common,
                                we know the importance of protecting consumers' identity. We hold no personal data which
                                means you remain safe online.
                            </Text>
                        </Column>
                        <Column css={columnStyle}>
                            <Text css={textStyle}>
                                AgePass has been designed and developed to be a leading global age verification solution
                                and to be able to comply with all compulsory regulatory and legal requirements.
                            </Text>
                        </Column>
                    </Flex>
                </Card>
            </Background>
        </Section>
    )
};

export default withTheme(SectionWhatMakesAgePass);


const textStyle = css`
    margin: 0;

    ${media.laptop`
        margin: 15px 0;
    `}
    
    ${media.phone`
        margin: 0;
    `}
`;

const spacing = css`
    padding: 78px 80px 47px 50px;
    
    ${media.desktop`
        padding: 88px 80px 97px 117px;
    `}

    ${media.laptop`
        padding: 51px 86px 48px 100px;
    `}

    ${media.phone`
        padding: 43px 31px 48px 43px;
    `}
`;

const columnStyle = css`
    width: 350px;

    ${media.desktop`
        width: 360px;
        margin-right: 61px;
    `}

    ${media.laptop`
        width: auto;
    `}

    ${media.phone`
        width: auto;
    `}
`;