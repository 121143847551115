import React from "react";
import styled, { withTheme } from "styled-components";
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Styles
import {Section} from "../styles";

// Components
import { Background } from "./index";
import FooterMobileNavigation from "../sections/FooterMobileNavigation";

// Images
import secpay from '../images/footer/Group-1481.png';
import integity from '../images/footer/Image-185.png';
import yoti from '../images/footer/Image-183.png';
import epay from '../images/footer/Image-182.png';
import mojo from '../images/footer/Image-180.png';
import paypoint from '../images/footer/Image-181.png';
import quorum from '../images/footer/Image-184.png';
import paysafe from '../images/footer/Image-178.png';
import bbfc from '../images/footer/Image-177.png';
import digital from '../images/footer/Image-176.png';
import bill from '../images/footer/CCBill-logo.png';

const LOGOS = [
    integity,
    yoti,
    epay,
    mojo,
    paypoint,
    secpay,
    quorum,
    bill,
    paysafe,
    digital,
    bbfc,
];

const Footer = ({ theme }) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);

    return (
        <Section>
            <Background color={theme.primaryColorTint1} >
                { isTablet && <FooterMobileNavigation/> }
                <LogosWrapper>
                    <Wrapper>
                        {LOGOS.map(logo => <Item key={logo} src={logo} alt={logo}/>)}
                    </Wrapper>
                </LogosWrapper>
                <Copyright>
                    AVSecure LLC © 2019 • All rights reserved
                </Copyright>
            </Background>
        </Section>
    )
};

export default withTheme(Footer);

const Item = styled.img`
    margin: auto 21px;
    padding: 10px 0;
    max-width: 100%;
    flex-shrink: 0;
    align-self: flex-start;
`;

const LogosWrapper = styled.div`
    width: 630px;
    ${media.tablet`
        width: 100%;
            ${Item}{
                margin: auto 18px;
            }
    `}

    ${media.phone`
        width: 100%;
        ${Item}{
            margin: auto 18px;
        }
    `}
`;

const Wrapper = styled.div`
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding: 20px 0 0 10px;
`;

const Copyright = styled.div`
    opacity: 0.5;
    font-family: ${({theme}) => theme.fontFamily};
    font-size: 14px;
    line-height: 1.57;
    text-align: left;
    color: #ffffff;
    padding: 15px 0 0 40px;

    ${media.tablet`
        font-size: 12px;
        padding: 0 0 22px 25px;
    `}

    ${media.phone`
        font-size: 12px;
        padding: 0 0 22px 25px;
    `}
`;

