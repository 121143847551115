import React from 'react';

// Styles
import {Content} from "../styles";

// Sections
import SectionWhatIsAgePass from "./SectionWhatIsAgePass";
import SectionOnlineAgeVerification from "./SectionOnlineAgeVerification";
import SectionHowItWorks from "./SectionHowItWorks";
import SectionWhatMakesAgePass from "./SectionWhatMakesAgePass";
import SectionAVC from "./SectionAVC";
import SectionPaysafe from "./SectionPaysafe";
import SectionGetInTouch from "./SectionGetInTouch";
import Footer from "../components/Footer";

const MainContentContainer = () => {

    return (
        <Content>
            <SectionWhatIsAgePass/>
            <SectionOnlineAgeVerification/>
            <SectionHowItWorks/>
            <SectionWhatMakesAgePass/>
            <SectionAVC/>
            <SectionPaysafe/>
            <SectionGetInTouch/>
            <Footer/>
        </Content>
    )
};

export default MainContentContainer;



