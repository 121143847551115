import React from "react";
import styled, {withTheme, css } from "styled-components";
import useMedia from 'react-use/lib/useMedia';
import { media } from '../theme';

// Styles
import {Column, Flex, Head, Section, SmallHead, Text} from "../styles/index";

// Components
import {Background, Card, Highlight} from "../components/index";

// Images
import getInTouch from '../images/get-in-touch.svg';

const SectionGetInTouch = ({theme}) => {
    const isTablet = useMedia(`(max-width: ${theme.sizes.laptop}px)`);

    return (
        <Section id="contact">
            <Background
                color={theme.secondaryColor}
                padding={{phone: '70px 19px 55px 19px', def: '79px 60px 100px 18px', laptop: '70px 118px 104px 124px'}}
            >
                <Card
                    spacing={spacing}
                    margin={{laptop: '0',  def: '0 0 0 -40px'}}
                >
                    <Flex>
                        <Column css={columnStyle}>
                            <SmallHead>
                                Get in touch
                            </SmallHead>
                            <Head>
                                We value both our customers privacy, and feedback.
                            </Head>
                            <Column width={isTablet ? null : 300}>
                                <Text>
                                    If you have a question, query or issue with your AgePass, please get in touch with 
                                    us through our <a href="https://avsecure.com/contact" target="_blank">
                                    <Highlight>contact form.</Highlight></a>
                                    {/* If you have a question, query or issue with your AgePass, please get in touch with
                                    us through <a href="https://twitter.com/avsecuresupport" target="_blank">
                                    <Highlight>@avsecuresupport</Highlight></a> If you have a business enquiry, please
                                    get in touch <a href="https://avsecure.com/for-merchants" target="_blank"
                                                    aria-label="for-merchants">
                                    <Highlight>here</Highlight></a> */}
                                </Text>
                            </Column>
                        </Column>
                    </Flex>
                    <Container>
                        <Birds src={getInTouch} alt="get in touch"/>
                    </Container>
                    <SecondContainer>
                        <PartBirds src={getInTouch} alt="get in touch"/>
                    </SecondContainer>
                </Card>
            </Background>
        </Section>
    )
};

export default withTheme(SectionGetInTouch);

const columnStyle = css`
    width: 403px;
    ${media.laptop`
        width: 95%;
    `}

    ${media.phone`
        width: 250px;
    `}
`;

const Container = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(45px,-37px);
    overflow: visible;

    ${media.laptop`
        transform: translate(137px,-50px);
    `}

    ${media.phone`
        transform: translate(18px,-37px);
    `}
`;

const Birds = styled.div`
    width: 330px;
    height: 365px;
    background-image: url(${props => props.src});

    ${media.laptop`
        width: 200px;
        background-size: 200px;
        background-repeat: no-repeat;
    `}

    ${media.phone`
        width: 105px;
        background-size: 150px;
        background-repeat: no-repeat;
    `}
`;

const SecondContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: visible;

    ${media.laptop`
        transform: translate(0,0);
    `}

    ${media.phone`
        transform: translate(0,0);
    `}
`;

const PartBirds = styled.div`
    width: 137px;
    height: 100px;
    background-image: url(${props => props.src});

    ${media.laptop`
        width: 80px;
        height: 80px;
        background-size: 200px;
        background-repeat: no-repeat;
        `}

    ${media.phone`
        width: 60px;
        height: 60px;
        background-size: 150px;
        background-repeat: no-repeat;
    `}
`;

const spacing = css`
    padding: 52px 10px 40px 50px;
    
    ${media.desktop`
        padding: 76px 10px 58px 117px;
    `}

    ${media.laptop`
        padding: 58px 80px 50px 80px;
    `}

    ${media.phone`
        padding: 36px 42px 20px 42px;
    `}
`;
